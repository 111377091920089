import Config from '../config.json'
import { useState, useEffect, useCallback } from 'react'
import { ethers } from "ethers"
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { TransactionApi } from '../apis/TransactionApi'
//import { Link } from "react-router-dom";

import TransactionList from '../components/TransactionList'

//const axios = require('axios').default;

const Txs = () => {
    
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [blockContent, setTxsContent] = useState([])
    const [blockNumber, setBlockNumber] = useState(parseInt(params.blockNumber))
    const [txs, setTxs] = useState([])
    const [txsCount, setTxsCount] = useState([])
    const [pageCountData, setPageCountData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [maxPageNumber, setMaxPageNumber] = useState(1);

    const initialState = {
        address: "",
        pageNumber: 1,
        pageSize: 20,
        isSecret:false
    };

    const getLatestTransactions = async () => {
        try
        {
             //console.log("Tnx Burası")
             await TransactionApi.getLatestTransactions(initialState)
             .then((response) => 
             {
                 console.log(response.data);
                 setTxs(response?.data?.transactions);
                 setTxsCount(response?.data?.transactionCount);

                 var list = []
                 var page = Number(response?.data?.transactionCount) / 20;
                 //console.log(page);
                 var count = Math.ceil(page);
                 console.log(count);
                 for (var i=1;i<count+1;i++) {
                     list.push({'id':i,'text':i});
                 }
                 setMaxPageNumber(count);
                 setPageCountData(list);
             })
             .catch(function (error) {
             // handle error
                 //console.log(error);
             });
        }
        catch(err)
        {
            //console.log(err);
        }
    }

    //change page
    const changePage = useCallback(async (id) => {
        //console.log(id);
        initialState.pageNumber=id;
        setPageNumber(id);
        getLatestTransactions();
    }, [initialState])

    //previous page
    const previous = useCallback(async () => {
        //console.log(maxPageNumber);
        if(pageNumber>1)
        {
            var number = pageNumber -1;
            initialState.pageNumber=number;
            setPageNumber(number);
            getLatestTransactions();
        }
        
    },[initialState])

    //next page 
    const next = useCallback(async () => {
        //console.log(maxPageNumber);
        if(pageNumber<maxPageNumber)
        {
            var number = pageNumber + 1;
            initialState.pageNumber=number;
            setPageNumber(number);
            getLatestTransactions();
        }
        
    },[initialState])

    // ---------------------------------------------------------------------------------------------------------- //
    // const eth_getTransactionByBlockNumber = async () => {

    //     let provider = new ethers.providers.JsonRpcProvider(Config.node);

    //     //verify if metamask is connected
    //     if (window.ethereum) {
    //         provider = new ethers.providers.Web3Provider(window.ethereum);
    //     }

    //     const block = await provider.getBlock(blockNumber)
    //     let txs = []

    //     block.transactions.forEach(async (tx) => {
    //         const txData = await provider.getTransaction(tx)
    //         txData.method = txData.data.slice(0, 10)
    //         txs.push(txData)
    //     })

    //     setTxsContent(txs)
    // }

    // ---------------------------------------------------------------------------------------------------------- //
    useEffect(() => {
        let timer = setTimeout(() => {

            if(pageNumber==1)
            {
                setLoading(true)
                //setBlockNumber(parseInt(params.blockNumber))
                getLatestTransactions()
                setLoading(false)
            }
        }, 10000);
        return () => clearTimeout(timer)
        // if (txs.length === 0) {
        //     setLoading(true)
        //     getLatestTransactions()
        //     setLoading(false)
        // }
     })
     
      if (loading) return (
        <main style={{ padding: "1rem 0" }} className='app-body'>
          <h4 className='Title'>Transactions</h4>
          <Spinner animation="border" style={{ display: 'inline-grid', marginTop:'25%' }} />
          <div>Loading transaction</div>
        </main>
      )

      // Render ---------------------------------------------------------------------------------------------------------- //
      return (
        <main style={{ padding: "1rem 0" }} className='app-body'>
            <h4 className='Title'>Transactions</h4>
            &nbsp;
            <TransactionList txs={txs} count={txsCount}/>
            <span >A total of {txsCount} transaction(s) found.</span>
            <div className="row mt-3 ">
                            {/* <div className="col-sm-12 col-md-5" style={{ float: 'left' }}>
                                <div className="dataTables_info" id="myDataTable_info" role="status" aria-live="polite">Showing 1 to 7 of 7 entries</div>
                            </div> */}
                            <div className="col-sm-12 col-md-12" >
                                <div className="dataTables_paginate paging_simple_numbers" id="myDataTable_paginate">
                                    <ul className="pagination table-responsive mb-2">
                                        <li className="paginate_button page-item previous" id="myDataTable_previous"><a href="#" onClick={() => previous()}  className="page-link">Previous</a></li>
                                        {pageCountData.map(item => (
                                            <li key={item.id} className={ item.id == pageNumber ? "paginate_button page-item active" : "paginate_button page-item" }><a href="#"  onClick={() => changePage(item.id)} key={item.id} className="page-link">{item.text}</a></li>
                                        ))}
                                        {/* <li className="paginate_button page-item active"><a href="#!" className="page-link">1</a></li> */}
                                        <li className="paginate_button page-item next" id="myDataTable_next"><a href="#" onClick={() => next()} className="page-link">Next</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
        </main>
    );
}
export default Txs