import { Card, ListGroup, Row, Col } from 'react-bootstrap'
//import { Link } from "react-router-dom";

const AddressOverview = ({ address, tokenBalance }) => {
    if(tokenBalance==null)
    tokenBalance=0;
    // Render ---------------------------------------------------------------------------------------------------------- //
    return (
        <Card className="infobox box">
            <Card.Header>
                <Card.Title><b>Overview</b></Card.Title>
            </Card.Header>
            <Card.Body>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <Row className="list">
                            <Col md={3} className="col-list">
                                Balance
                            </Col>
                            <Col md={9} className="col-list">
                                {address.balance} CYX
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <Row className="list">
                            <Col md={3} className="col-list">
                                CYX Value
                            </Col>
                            <Col md={9} className="col-list">
                                ${address.value}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <Row className="list">
                            <Col md={3} className="col-list">
                                Tokens
                            </Col>
                            <Col md={9} className="col-list">
                                ${tokenBalance.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    <ListGroup.Item></ListGroup.Item>
                </ListGroup>
            </Card.Body>
        </Card>
    );
}
export default AddressOverview;