import { Button, Card } from 'react-bootstrap'
import { Link } from "react-router-dom";

const Transaction = ({ transaction }) => {

    const receipt = {}
    for (let key in transaction.receipt) {
        receipt[key] = transaction.receipt[key]
    }
    const txStatusText = receipt.status ? 'success' : 'danger'
    const txStatusName = receipt.status ? 'Success' : 'Failed'

    let copyIcon = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>

    function copyToClipboard(text) {

        var textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        textArea.remove();
    }

    // Render ---------------------------------------------------------------------------------------------------------- //
    return (
    <Card style={{backgroundColor:'#030f19'}}>
        <Card.Header>
            <Card.Title className="std-card-title"  style={{color:'#b0b0b0'}}>Overview</Card.Title>
        </Card.Header>
        <Card.Body className="std-card-info" style={{backgroundColor:'rgba(0,0,0,0.0)'}}>
           
           <div className='card p-5-custom mb-3' style={{backgroundColor:'rgba(0,0,0,0.0)', color:'#8f8f8f'}}>
                <div className='row mb-4'>
                    <div className='col-md-3 text-dt mb-2 mb-md-0'>
                    <button type="button" style={{marginRight:'10px', background:'#bababa'}} className="ml-2 btn btn-custom-info btn-sm">&nbsp;&nbsp;&nbsp;&nbsp;</button>
                    Transaction Hash:
                    </div>
                    <div className='col-md-9'>
                        <span id="referralLink-1" className="text-break">
                            <span id="spanTxHash" className="me-1">{transaction.localHash}</span>
                        </span>
                    </div>
                </div>

                <hr className="opacity-75 mt-0 mb-5 hr-margin-bottom"></hr>

                <div className='row align-items-center mb-4'>
                    <div className='col-auto col-md-3 text-dt'>
                        <button type="button" style={{marginRight:'10px', background:'#bababa'}} className="ml-2 btn btn-custom-info  btn-sm">&nbsp;&nbsp;&nbsp;&nbsp;</button>
                            Status:
                    </div>
                    {transaction.status == 'Success' ? 
                    
                    <div className='col col-md-9'>
                    <span className="badge bg-success-background" ><i className="fa fa-check-circle me-1"></i>Success</span>
                    </div>
                    
                    : 
                    
                    <div className='col col-md-9'>
                    <span className="badge bg-warning-background" style={{background:'#eba036'}} ><i className="fa fa-check-circle me-1"></i>Pending</span>
                    </div>
                    
                    }
                    {/* <div className='col col-md-9'>
                    <span className="badge bg-success-background" ><i className="fa fa-check-circle me-1"></i>Success</span>
                    </div> */}
                </div>

                <div className="row mb-4">
                    <div className="col-md-3 text-dt mb-2 mb-md-0">
                        <button type="button" style={{marginRight:'10px',background:'#bababa'}} className="ml-2 btn btn-custom-info  btn-sm">&nbsp;&nbsp;&nbsp;&nbsp;</button>
                            Block
                    </div>
                    <div className="col-md-9">
                        <div className="d-flex align-items-center gap-2">
                            {/* <span className="d-flex align-items-center gap-1"><a href="/block/33187269">{transaction.blockNumber} </a></span> */}
                            <span className="d-flex align-items-center gap-1"><a href="/blocks">{transaction.blockNumber} </a></span>
                            <span className="badge bg-secondary" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top">{transaction.requiredConfirmations}/{transaction.confirmations} Block Confirmations</span>
                        </div>
                    </div>
                </div>

                <div id="ContentPlaceHolder1_divTimeStamp">
                    <div className="row mb-4">
                        <div className="col-md-3 text-dt mb-2 mb-md-0">
                        <button type="button" style={{marginRight:'10px', background:'#bababa'}} className="ml-2 btn btn-custom-info  btn-sm">&nbsp;&nbsp;&nbsp;&nbsp;</button>
                            Timestamp:
                        </div>
                        <div className="col-md-9">
                            <span id="clock"></span><i className="far fa-clock small me-1"></i>{ transaction.timeStamp } secs ago (<span id="showUtcLocalDate">{transaction.dateString}</span>)
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-3 text-dt mb-1 mb-md-0">
                    <button type="button" style={{marginRight:'10px', background:'#bababa'}} className="ml-2 btn btn-custom-info  btn-sm">&nbsp;&nbsp;&nbsp;&nbsp;</button>
                        Transaction Action: 
                    </div>
                    <div className="col-md-9">
                        <div className="d-flex flex-column gap-2" id="wrapperContent">
                            <div className="d-flex align-items-baseline">
                                <span className="text-secondary small me-1"><i className="fa fa-caret-right"></i></span>
                                <div className="d-flex flex-wrap align-items-center">
                                    <span className="text-muted me-1 me-1-white">{transaction.transferMethod}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="opacity-75 mt-0 mb-5 hr-margin-bottom"></hr>

                <div className="row mb-4">
                    <div className="col-md-3 text-dt mb-2 mb-md-0">
                    <button type="button" style={{marginRight:'10px', background:'#bababa'}} className="ml-2 btn btn-custom-info btn-sm">&nbsp;&nbsp;&nbsp;&nbsp;</button>
                        From:
                    </div>
                    <div className="col-md-9">
                        <div><span className="me-1"><a href={`/address/${transaction.from}`} className="text-break">{transaction.from}</a></span>
                      
                        </div>
                    </div>
                </div>


                <div className="row mb-4">
                    <div className="col-md-3 text-dt mb-2 mb-md-0">
                        <button type="button" style={{marginRight:'10px', background:'#bababa'}} className="ml-2 btn btn-custom-info btn-sm">&nbsp;&nbsp;&nbsp;&nbsp;</button>Interacted With (To):
                    </div>
                    <div className="col-md-9">
                        <div><i className="far fa-file-alt text-secondary "  data-bs-toggle="tooltip" aria-label="Contract"></i> <span className="me-1"><a href={`/address/${transaction.to}`} className="text-break">{transaction.to}</a></span></div>
                    </div>
                </div>

                <hr className="opacity-75 mt-0 mb-5 hr-margin-bottom"></hr>

                <div className='row mb-4'>
                    <div className='col-md-3 text-dt mb-1 mb-md-0'>
                        <button type="button" style={{marginRight:'10px', background:'#bababa'}} className="ml-2 btn btn-custom-info btn-sm">&nbsp;&nbsp;&nbsp;&nbsp;</button>
                        <span data-bs-toggle="tooltip">CYX20 Tokens Transferred:</span>
                    </div>
                    <div className='col col-md-9'>
                        <div className='d-flex flex-column gap-3 overflow-y-auto scrollbar-custom'>

                        {transaction?.transferList?.map((item, idx) => (
                            

                            <div className='row-count d-flex align-items-baseline' key={idx}>
                                  <span className="fw-medium me-1">Method <b>{item.transactionMethod}</b></span>
                                  <span className="d-inline-flex flex-wrap align-items-center">
                                    <span className="fw-medium me-1">From</span>
                                    <a href={`/address/${item.from}`}> <span className="d-block" data-bs-toggle="tooltip">{item.from.slice(0, 20)}</span> </a>
                                    <span className="fw-medium mx-1">To</span>
                                  <a href={`/address/${item.to}`}> <span className="d-block" data-bs-toggle="tooltip">{item.to.slice(0, 20)}</span> </a>
                                  </span>
                                  
                                  <span className="fw-medium mx-1" style={{margin:'0px !important'}}>For</span>
                                  <span className="me-1" data-bs-toggle="tooltip">{item.amount} (${item.totalBalance})</span>
                                  <a className="d-flex align-items-center gap-0.5" href={`/address/${item.contract}`}>
                                    <img src={item.symbolImage} width="16" className="rounded-circle me-0.5"></img>
                                    <span>
                                        <span className="text-wrap text-truncate">{item.name}</span>
                                    </span>
                                    <span className="text-muted">
                                    &nbsp;(<span className="text-wrap text-truncate" data-bs-toggle="tooltip">{item.symbol}</span>)
                                    </span>
                                  </a>
                                  <hr className="opacity-75 mt-0 mb-5 hr-margin-bottom"></hr>
                            </div>

                        ))}

                          
{/* 
                            <div className='row-count d-flex align-items-baseline'>
                                <span className="d-inline-flex flex-wrap align-items-center"><span className="fw-medium me-1">From</span><a href="/token/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c?a=0x5b84334b359fb6a41aac03247fcabd5a6d946516"><span className="d-block" data-bs-toggle="tooltip">0x5b8433...6D946516</span></a><span className="fw-medium mx-1">To</span><a href="/token/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c?a=0x94db8b6d6975808af376677f39ce5782d20936b7"><span className="d-block text-truncate" data-bs-toggle="tooltip">PancakeSwap V2: QJL 5</span></a><span className="fw-medium mx-1">For</span><span className="me-1" data-bs-toggle="tooltip">0.4887</span><span className="text-muted me-1">($112.78)</span><a className="d-flex align-items-center gap-0.5" href="/token/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"><img src="/token/images/bnbchain2_32.png" width="16" className="rounded-circle me-0.5"/><span><span className="text-wrap text-truncate" data-bs-toggle="tooltip">Wrapped BNB...</span></span><span className="text-muted">(<span className="text-wrap text-truncate" data-bs-toggle="tooltip">WBNB...</span>)</span></a></span>
                            </div>
                            <div className="row-count d-flex align-items-baseline">
                                <span className="d-inline-flex flex-wrap align-items-center"><span className="fw-medium me-1">From</span><a href="/token/0xe412e9a64023a12c6ac0d100075898f562fd2403?a=0x94db8b6d6975808af376677f39ce5782d20936b7"><span className="d-block text-truncate" data-bs-toggle="tooltip">PancakeSwap V2: QJL 5</span></a><span className="fw-medium mx-1">To</span><a href="/token/0xe412e9a64023a12c6ac0d100075898f562fd2403?a=0xc3add8558bb6d22b2a4a6f119e50bdd08d0b5795"><span className="d-block" data-bs-toggle="tooltip">0xC3ADD8...8D0b5795</span></a><span className="fw-medium mx-1">For</span><span className="me-1">154,396.913178243085756506</span><a className="d-flex align-items-center gap-0.5" href="/token/0xe412e9a64023a12c6ac0d100075898f562fd2403"><img src="/assets/bsc/images/svg/empty-token.svg?v=23.10.4.5" className="rounded-circle me-0.5" width="16"/><span><span className="text-wrap text-truncate" data-bs-toggle="tooltip">QJL...</span></span><span className="text-muted">(<span className="text-wrap text-truncate" data-bs-toggle="tooltip">QJL...</span>)</span></a></span>
                            </div> */}
                        </div>
                    </div>
                </div>

                <hr className="opacity-75 mt-0 mb-5 hr-margin-bottom"></hr>

                <div className='row mb-4'>
                    <div className='col-md-3 text-dt mb-2 mb-md-0'>
                        <button type="button" style={{marginRight:'10px', background:'#bababa'}} className="ml-2 btn btn-custom-info btn-sm">&nbsp;&nbsp;&nbsp;&nbsp;</button>
                        Value :
                    </div>

                    <div className='col-md-9'>
                    <div className="d-flex align-items-center gap-1"><span data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top"><b>{transaction.amount}</b> {transaction.assetName}</span> <span className="text-muted me-1-white">(${transaction.totalBalance})</span></div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-3 text-dt mb-2 mb-md-0">
                        <button type="button" style={{marginRight:'10px', background:'#bababa'}} className="ml-2 btn btn-custom-info btn-sm">&nbsp;&nbsp;&nbsp;&nbsp;</button>Transaction Fee:
                    </div>
                    <div className="col-md-9">
                        <span id="ContentPlaceHolder1_spanTxFee"><div className="d-flex align-items-center gap-1"><span data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top"><b>{transaction.feeAmount}</b> {transaction.assetName}</span> <span className="text-muted me-1-white">(${transaction.feeBalance})</span></div></span>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3 text-dt mb-2 mb-md-0">
                        <button type="button" style={{marginRight:'10px', background:'#bababa'}} className="ml-2 btn btn-custom-info btn-sm">&nbsp;&nbsp;&nbsp;&nbsp;</button>Gas Price:
                    </div>
                    <div className="col-md-9">
                        <span id="ContentPlaceHolder1_spanGasPrice" data-bs-toggle="tooltip">0 Cwei <span className="text-muted me-1-white">(0<b>.</b>000000000 CYX)</span></span>
                    </div>
                </div>
                

                

           </div>
            {/* <ListGroup variant="flush">
                <ListGroup.Item><b>Transaction Hash</b>: {transaction.hash}</ListGroup.Item>
                <ListGroup.Item><b>Status</b>: <Button variant={`${txStatusText}`} size="sm" className="ml-2">{`${txStatusName}`}</Button></ListGroup.Item>
                <ListGroup.Item><b>Block Number</b>: <Link to={`/block/${transaction.blockNumber}`}>{transaction.blockNumber}</Link> <Button variant="secondary" size="sm" className="ml-2">{transaction.confirmations} Block confirmations</Button></ListGroup.Item>
                <ListGroup.Item><b>Timestamp</b>: {transaction.block.timediff} sec(s) ago {transaction.block.humandate} </ListGroup.Item>
                <ListGroup.Item><b>From</b>: <Link to={`/address/${transaction.from}`}>{transaction.from ? transaction.from : null}</Link> <span onClick={() => copyToClipboard(transaction.from)}>{copyIcon}</span></ListGroup.Item>
                <ListGroup.Item><b>To</b>: <Link to={`/address/${transaction.to}`}>{transaction.to ? transaction.to : null}</Link> <span onClick={() => copyToClipboard(transaction.to)}>{copyIcon}</span></ListGroup.Item>
                <ListGroup.Item><b>Value</b>: {transaction.value.toString() / 10 ** 18} Ethers</ListGroup.Item>
                <ListGroup.Item><b>Gas Price</b>: {transaction.gasPrice / 10 ** 9} gwei</ListGroup.Item>
                <ListGroup.Item><b>Gas Used</b>: {receipt.gasUsed / 10 ** 0} gwei</ListGroup.Item>
                <ListGroup.Item><b>Gas Limit</b>: {transaction.gas / 10 ** 0} gwei</ListGroup.Item>
                <ListGroup.Item><b>Nonce</b>: {transaction.nonce}</ListGroup.Item>
                <ListGroup.Item><b>Transaction Index</b>: {transaction.transactionIndex}</ListGroup.Item>
                <ListGroup.Item><b>Transaction Type</b>: {receipt.type}</ListGroup.Item>
                <ListGroup.Item ><b>Input</b>: {transaction.input}</ListGroup.Item>
                <ListGroup.Item ><b>V</b>: {transaction.v}</ListGroup.Item>
                <ListGroup.Item ><b>R</b>: {transaction.r}</ListGroup.Item>
                <ListGroup.Item ><b>S</b>: {transaction.s}</ListGroup.Item>
                <ListGroup.Item ><b>logs Bloom</b>: {receipt.logsBloom}</ListGroup.Item>
            </ListGroup> */}
        </Card.Body>
    </Card>
    );
}
export default Transaction