import React from "react";
import { Card, Button, Table } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { getAddress, linkCopyAddress } from '../class/Tools'

const TransactionList = ({txs, count}) => {
    console.log(count);
    return (
        <Card style={{marginBottom:'15px'}}>
           <Card.Header>
                <Card.Title className="std-card-title">Transaction List</Card.Title>
            </Card.Header>
             <Card.Body>
                <Table striped bordered hover responsive size="sm" variant="light">
                    <thead>
                        <tr>
                            <th>Txn Hash</th>
                            <th>Method</th>
                            <th>Block</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Value</th>
                            <th>Txn Fee</th>
                        </tr>
                    </thead>
                    <tbody>
                        {txs.map((item, idx) => (
                            <tr key={idx}>
                                <td><Link to={`/tx/${item.hashLocal}`}>{item.hashLocal ? item.hashLocal.slice(0, 20) + '...': null}</Link></td>
                                {/* <td>{item.hashLocal.slice(0, 20)}</td> */}
                                <td><Button variant="secondary btn-list" size="sm" className="ml-2">{item.method}</Button></td>
                                <td><Link to={`/block/${item.blockHeight}`}>{item.blockHeight}</Link></td>
                                <td><Link to={`/address/${item.from}`}>{item.from ? linkCopyAddress(item.from) : null}</Link></td>
                                <td>{item.to ? <Link to={`/address/${item.to}`}>{linkCopyAddress(item.to)}</Link> : null}</td>
                                <td>{item.amount} {item.symbol}</td>
                                <td>{item.fee} gwei</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};
export default TransactionList;