import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const TransactionApi = {
    getLatestTransactions: async function (data, cancel = false) {
        const response = await api.request({
            url: `Transaction/LatestTransactions`,
            method: "POST",
            data: JSON.stringify(data), 
                headers:{
                    'Content-Type': 'application/json; charset=utf-8'
                },
            signal: cancel ? cancelApiObject[this.getLatestTransactions.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getTransaction: async function (data, cancel = false) {
        const response = await api.request({
            url: `Transaction/LatestTransactions`,
            method: "POST",
            data: JSON.stringify(data), 
                headers:{
                    'Content-Type': 'application/json; charset=utf-8'
                },
            signal: cancel ? cancelApiObject[this.getTransaction.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    AllTransactions: async function ({address}, cancel = false) {
        const response = await api.request({
            url: `Transaction/AllTransactions?address=${address}`,
            method: "POST",
            signal: cancel ? cancelApiObject[this.GetByContractHash.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    GetByTransactionHash: async function ({hash}, cancel = false) {
        const response = await api.request({
            url: `Transaction/GetByTransactionHash?hash=${hash}`,
            method: "POST",
            signal: cancel ? cancelApiObject[this.GetByContractHash.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(TransactionApi)