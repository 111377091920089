import Config from '../config.json'
import { useState, useEffect } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useParams } from "react-router-dom";
import { TransactionApi } from '../apis/TransactionApi'
import Transaction from '../components/Transaction'
import TransactionLogs from '../components/TransactionLogs'

const axios = require('axios').default;

const Tx = ({ transactionHash }) => {
    const [loading, setLoading] = useState(true)
    const params = useParams()
    const [txlogs, setTxlogs] = useState(null)
    const [timespam, setTimespam] = useState(null)
    const [transaction, setTransaction] = useState(
        {
            localHash: params.transactionHash,
            remoteHash: 0,
            status: '',
            message:'',
            blockNumber:0,
            confirmations:0,
            timeStamp:0,
            TransferMethod:'',
            from: '',
            to: '',
            transferList:[],
            amount:0,
            price:0,
            totalBalance:0,
            feeAmount: 0,
            feeBalance: 0,
            gasAmount: 0,
            gasBalance: 0,
            dateString:''
        }
    )

    const getTransaction = async (_txHash) => {
        try
        {
            await TransactionApi.GetByTransactionHash({'hash':_txHash})
            .then((response) => 
            {
                console.log('tx by hash : ' + _txHash )
                console.log(response.data);
                setTimespam(response.data.timeStamp);
                response.data.timeStamp = Math.round(+new Date()/1000) - response.data.timeStamp
                setTransaction(response?.data);
            })
            .catch(function (error) {
                console.log(error);
            })
        }
        catch(err)
        {
            //console.log(err);
        }
        setLoading(false)
    }

    useEffect(() => {
        let timer = setTimeout(() => {
            console.log("Start Tx");
            getTransaction(params.transactionHash)
        }, 4000);
        return () => clearTimeout(timer)
    });

    // useEffect(() => {
    //     let timerChange = setTimeout(() => {
    //         console.log("Çalıştım");
    //         transaction.timeStamp = Math.round(+new Date()/1000) - Number(timespam);
    //         console.log(transaction.timeStamp);
    //         setTransaction(transaction);
    //     }, 100);
    //     return () => clearTimeout(timerChange)
    // });

    if (loading) return (
    <main style={{ padding: "1rem 0" }} className='app-body'>
        <h4 className="Title" style={{color:'white'}}>Transaction Details</h4>
        <Row className="justify-content-center" style={{color:'white'}}>
            <Col xs={12} md={12} lg={12}>
                Loading transaction...
                <Spinner animation="border" variant="secondary" />
            </Col>
        </Row>
    </main>
    )

      // Render ---------------------------------------------------------------------------------------------------------- //
    return (
        <main style={{ padding: "1rem 0" }} className='app-body'>
            <h4 className="Title" style={{color:'white'}}>Transaction Details</h4>
            <Row className="justify-content-center">
                <Col xs={12} md={12} lg={12}>
                    <Transaction transaction={transaction} />
                    <br/>
                    {transaction.receipt ? (
                        <TransactionLogs transaction={transaction} />
                    ) : null}
                </Col>
            </Row>
        </main>
    );
}
export default Tx