// ------------------------------------------------------------------------------------------------- //
// Etherscan
// ------------------------------------------------------------------------------------------------- //

// Configuration ----------------------------------------------------------------------------------- //
import Config from './config.json'

// Modules ----------------------------------------------------------------------------------------- //
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Row,Col,Button, Spinner } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { ethers } from "ethers"

// import axios from 'axios'

// Export ------------------------------------------------------------------------------------------ //
import './App.css';

import Navigation from './components/Navbar';
import HomeNavigation from './components/HomeNavbar';
import Footer from './components/Footer.js';
import HTTP404 from './components/404.js'
//import { copyToClipboard, getAddress, linkAddress } from './class/Tools'
//import { web3Handler, getProvider, isContract, loadContract2 } from './class/Evm'
//import SearchBar from './components/SearchBar';

// Routes ------------------------------------------------------------------------------------------ //
import Home from './routes/Home.js'
import Accounts from './routes/Accounts.js'
import Address from './routes/Address.js'
import Block from './routes/Block.js'
import Blocks from './routes/Blocks.js'
import Charts from './routes/Charts.js'
import Contract from './routes/Contract.js'
import Logs from './routes/Logs.js'
import Token from './routes/Token.js'
import Tokens from './routes/Tokens.js'
import TokensTx from './routes/TokensTx.js'
import Tx from './routes/Tx.js'
import Txs from './routes/Txs.js'
import Topstats from './routes/Topstats.js'
import Profile from './routes/Profile.js'
import Applications from './routes/Apps.js'
import Interface from './routes/Interface.js'
import Interfaces from './routes/Interfaces.js'

// Functions --------------------------------------------------------------------------------------- //
function App() {
    const [loading, setLoading] = useState(false)
    const [account, setAccount] = useState(null)
    const [networkName, setNetworkName] = useState('Network')
    const [chainId, setChainId] = useState(null)
    const [stats, setStats] = useState({
        latest: 'latest',
        pending: 'pending',
        gasPrice: null,
        gasLimit: null,
        blockTime: null,
        blockReward: null,
        totalSupply: null
    })

  // MetaMask Login/Connect ------------------------------------------------------------------------ //
  const web3Handler = async () => {
    // try
    // {
    //   const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    //   setAccount(accounts[0])

    //   // Get provider from Metamask
    //   const provider = new ethers.providers.Web3Provider(window.ethereum)

    //   // Set signer
    //   const signer = provider.getSigner()

    //   window.ethereum.on('chainChanged', (chainId) => {
    //     window.location.reload();
    //   })

    //   window.ethereum.on('accountsChanged', async function (accounts) {
    //     setAccount(accounts[0])
    //     await web3Handler()
    //   })
    // }
    // catch(err)
    // {
    //   //console.log(err);
    // }

  }

  const setNetwork = async () => {
    // try {
    //     await window.ethereum.request({
    //       method: 'wallet_switchEthereumChain',
    //       params: [{ chainId: '0x38' }],
    //     });
    //   } catch (switchError) {
    //     // This error code indicates that the chain has not been added to MetaMask.
    //     if (switchError.code === 4902) {
    //       try {
    //         await window.ethereum.request({
    //           method: 'wallet_addEthereumChain',
    //           params: [
    //             {
    //               chainId: '0x38',
    //               chainName: 'Binance Smart Chain Mainnet',
    //               rpcUrls: ['https://bsc-dataseed1.binance.org'] /* ... */,
    //               nativeCurrency: {
    //                 name: "Binance Chain Native Token",
    //                 symbol: "bnb",
    //                 decimals: 18,
    //               },
    //               blockExplorerUrls: ['https://bscscan.com']
    //             },
    //           ],
    //         });
    //       } catch (addError) {
    //         // handle "add" error
    //       }
    //     }
    //     // handle other "switch" errors
    //   }
  }

  const setBTCC = async () => {
    // const tokenAddress = '0xC48a0352F22eb94C002cA712117346c1DdFb725C';
    // const tokenSymbol = 'BTCc';
    // const tokenDecimals = 8;
    // const tokenImage = 'https://wallet.cyx.finance/bitcoin-logo-svg.svg';
    
    //try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    //   const wasAdded = await window.ethereum.request({
    //     method: 'wallet_watchAsset',
    //     params: {
    //       type: 'ERC20', // Initially only supports ERC20, but eventually more!
    //       options: {
    //         address: tokenAddress, // The address that the token is at.
    //         symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
    //         decimals: tokenDecimals, // The number of decimals in the token
    //         image: tokenImage, // A string url of the token logo
    //       },
    //     },
    //   });
    
    //   if (wasAdded) {
    //     console.log('Thanks for your interest!');
    //   } else {
    //     console.log('Your loss!');
    //   }
    // } catch (error) {
    //   //console.log(error);
    // }
  }

  const loadNetwork = async () => {
    // try
    // {
    //   //get the network chain id
    //   const _chainId = await window.ethereum.request({ method: 'eth_chainId' });

    //   //chainid hex to int
    //   const chainIdInt = parseInt(_chainId, 1);
    //   setChainId(chainIdInt)

    //   //if chain id is 1, then it is mainnet (eth)
    //   if (chainIdInt === 1) {
    //     setNetworkName('Mainnet')
    //   } else if (chainIdInt === 3) {
    //     setNetworkName('Ropsten')
    //   } else if (chainIdInt === 4) {
    //     setNetworkName('Rinkeby')
    //   } else if (chainIdInt === 5) {
    //     setNetworkName('Goerli')
    //   } else if (chainIdInt === 10) {
    //     setNetworkName('Optimism')
    //   } else if (chainIdInt === 42) {
    //     setNetworkName('Kovan')
    //   } else if (chainIdInt === 56) {
    //     setNetworkName('Binance Smart Chain')
    //   } else if (chainIdInt === 137) {
    //     setNetworkName('Polygon Mainnet')
    //   } else if (chainIdInt === 250) {
    //     setNetworkName('Fantom')
    //   } else if (chainIdInt === 1285) {
    //     setNetworkName('Moonriver')
    //   } else if (chainIdInt === 1337) {
    //     setNetworkName('Ganache')
    //   } else if (chainIdInt === 3712) {
    //     setNetworkName('Legalsuite')
    //   } else if (chainIdInt === 31337) {
    //     setNetworkName('Hardhat')
    //   } else if (chainIdInt === 35478) {
    //     setNetworkName('CYX Mainnnet')
    //   } else if (chainIdInt === 37012) {
    //     setNetworkName('LS Lab')
    //   } else if (chainIdInt === 42161) {
    //     setNetworkName('Arbitrum One')
    //   } else if (chainIdInt === 43114) {
    //     setNetworkName('Avalanche Mainnet')
    //   } else if (chainIdInt === 75000) {
    //     setNetworkName('Resin')
    //   } else {
    //     setNetworkName('CYX Mainnet '+ chainIdInt)
    //   }
    // }
    // catch(err)
    // {
    //   //console.log(err);
    // }
  }

  // const loadLastBlock = async () => {

  //   let provider = new ethers.providers.JsonRpcProvider(Config.node);

  //   //verify if metamask is connected
  //   if (window.ethereum) {
  //       provider = new ethers.providers.Web3Provider(window.ethereum);
  //   }

  //   const pblock = await provider.getBlock('pending') || 0
  //   const lblock = await provider.getBlock('latest')
  //   setStats({
  //     pending: pblock.number.toString(),
  //     latest: lblock.number.toString()
  //   })

  // }

  useEffect(() => {

    // try
    // {
    //   if (networkName === 'Network') {
    //     //verify metamask is installed
    //     if (window.ethereum) {
    //       //on network change, loadNetwork
    //       window.ethereum.on('chainChanged', async (chainId) => {
    //         window.location.reload()
    //         await loadNetwork()
    //       })
    //     } else {
    //       console.log('No Metamask detected');
    //       setNetworkName('CYX Mainnet')
    //       setChainId(191919)
    //     }
    //   }
    //   let timer = setTimeout(() => {
    //     loadNetwork()
    //   }, 1000);
  
    //   return () => clearTimeout(timer)
    // }
    // catch(err)
    // {
    //   //console.log(err);
    // }
})

  // Render ---------------------------------------------------------------------------------------- //
  return (
    <BrowserRouter>
      <div className="App">
        <div>
          {window.location.pathname === '/'
          ? <Navigation/>
          : <Navigation/>
      }
        </div>
        <div className="container extra-container">
        {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
              <Spinner animation="border" style={{ display: 'flex' }} />
              <p className='mx-3 my-0'>Awaiting Metamask Connection...</p>
            </div>
          ) : (
          <Routes>
            <Route exact path="/" element={
              <Home/>
            } />

            <Route path="/tx/:transactionHash" element={
              <Tx/>
            } />

            <Route path="/address/:walletAddress" element={
              <Address/>
            } />

            <Route path="/blocks" element={
              <Blocks/>
            } />

            <Route path="/txs" element={
              <Txs/>
            } />


            {/* <Route path="/accounts" element={
              <Accounts/>
            } />

           

            

            <Route path="/block/:blockNumber" element={
              <Block/>
            } />
           

           
           
            <Route path="/txsPending" element={
              <Txs/>
            } />
            <Route path="/txsInternal" element={
              <Txs/>
            } />
            <Route path="/txs/:blockNumber" element={
              <Txs/>
            } />

            <Route path="/token/:tokenAddress" element={
              <Token/>
            } />
            <Route path="/tokens" element={
              <Tokens/>
            } />
            <Route path="/tokentxns/" element={
              <TokensTx/>
            } />
            <Route path="/contractsim/:contract" element={
              <Interface/>
            } />
            <Route path="/interface/:contract" element={
              <Interface/>
            } />
            <Route path="/interfaces" element={
              <Interfaces/>
            } />

            <Route path="/profile" element={
              <Profile/>
            } />
            <Route path="/apps" element={
              <Applications/>
            } />
            <Route path="/charts" element={
              <Charts/>
            } />
            <Route path="/topstats" element={
              <Topstats/>
            } />
            <Route path="/logs/:contract/:topic" element={
              <Logs/>
            } />
            <Route path="/logs/:contract" element={
              <Logs/>
            } />
            <Route path="*" element={
              <HTTP404/>
            } /> */}
          </Routes>)}
        </div>
        <div className="footer align-self-end" >
          <div className="container">
            <Row>
              <div className="col-md-12">
                <p>
                CYX Explorer is a tool for navigating and analyzing public and private CYX based blockchains. Blockchain explorer for CYX Network. Copyright <a href="https://www.cyx.finance" target="_blank" rel="noopener noreferrer">CYX Finance</a> 2022. All rights reserved.
                  </p>
                </div>
              {/* <div className="col-md-1">
                <p className="text-right">
                  <Button variant="secondary" size="sm" onClick={setBTCC}>Add BTCc to Metamask!&nbsp;<img src="https://raw.githubusercontent.com/MetaMask/metamask-extension/develop/app/images/icon-16.png"/></Button>
                </p>
              </div>
              <div className="col-md-1">
                <p className="text-right">
                  <Button variant="secondary" size="sm" onClick={setNetwork}>Add ETHc to Metamask!&nbsp;<img src="https://raw.githubusercontent.com/MetaMask/metamask-extension/develop/app/images/icon-16.png"/></Button>
                </p>
              </div> */}
            </Row>
            {/* <Row>
              <Col md={12}>
                <p>
                  
                </p>
                </Col>
            </Row> */}
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}
export default App;