import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const BlockApi = {
    getLatestBlocks: async function (data, cancel = false) {
        const response = await api.request({
            url: `Block/LatestBlocks`,
            method: "POST",
            data: JSON.stringify(data), 
                headers:{
                    'Content-Type': 'application/json; charset=utf-8'
                },
            signal: cancel ? cancelApiObject[this.getLatestBlocks.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(BlockApi)