import { binanceApi } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const BinanceApi = {
    getBTCData: async function (cancel = false) {
        const response = await binanceApi.request({
            url: "ticker/24hr?symbol=BTCUSDT",
            method: "GET",
            signal: cancel ? cancelApiObject[this.getBTCData.name].handleRequestCancellation().signal : undefined,
        });
        return response;
    },
    getETHData: async function (cancel = false) {
        const response = await binanceApi.request({
            url: "ticker/24hr?symbol=ETHUSDT",
            method: "GET",
            signal: cancel ? cancelApiObject[this.getETHData.name].handleRequestCancellation().signal : undefined,
        });
        return response;
    },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(BinanceApi)