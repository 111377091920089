import { useState, useEffect } from 'react'
import { Card, ListGroup, Row, Col } from 'react-bootstrap'
//import { Link } from "react-router-dom";

const AddressMoreinfo = ({ address, messageLine1 ,messageLine2 }) => {


  
    //if(address=='29yEJNpwmbrbHmo9i69XrcJuudMBgJrApF2sfVWi3gPX')

    // Render ---------------------------------------------------------------------------------------------------------- //
    return (
        <Card className="infobox box">
            <Card.Header>
                <Card.Title><b>More Info</b></Card.Title>
            </Card.Header>
            <Card.Body>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <Row className="list">
                            <Col md={3} className="col-list">
                                Tag
                            </Col>
                            <Col md={9} className="col-list">
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <Row className="list">
                            <Col md={12} className="col-list">
                            {messageLine1}
                            </Col>
                           
                        </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <Row className="list">
                            <Col md={12} className="col-list">
                            {messageLine2}
                            </Col>
                           
                        </Row>
                    </ListGroup.Item>
                    <ListGroup.Item></ListGroup.Item>
                </ListGroup>
                
            </Card.Body>
        </Card>
    );
}
export default AddressMoreinfo;