
import React from "react";
import { Button, Card, Row, Col, ListGroup } from 'react-bootstrap'
import { Link } from "react-router-dom"

const LatestBlocks = ({items}) => {
    return (
        <Card style={{backgroundColor:'#030f19'}}>
            <Card.Header style={{borderColor:'white', color:'white', borderWidth:'1px',borderColor:'#484646'}}>Latest Blocks</Card.Header>
            <Card.Body className="std-card-info" style={{backgroundColor:'rgba(0,0,0,0.0)',borderColor:'#484646'}}>
                <ListGroup variant="flush" className="list-group-item" style={{backgroundColor:'rgba(0,0,0,0.0)',borderColor:'#484646'}}>
                {items.map((item, idx) => (
                    <ListGroup.Item key={idx} style={{backgroundColor:'rgba(0,0,0,0.0)',borderColor:'#484646'}} >
                        <Row className="list"  style={{color:'#8f8f8f'}}>

                            <Col md={1} className="col-list">
                                <Link to={`/block/${item.height}`}  title={item.height}><Button style={{background:'#030f19'}} variant="secondary" size="sm" className="ml-2">Bk</Button></Link>
                            </Col>

                            <Col md={2} className="col-list">
                                <Row><Col><Link to={`/block/${item.height}`}>{item.height}</Link></Col></Row>
                                <Row><Col><small>{item.header.timeStamp} secs ago</small></Col></Row>
                            </Col>

                            <Col md={7} className="col-list">
                                <Row><Col>Miner: <Link to={`/address/${item.validatorAddress}`} title={item.validatorAddress}>{item.validatorAddress.slice(0, 13) + '...'+item.validatorAddress.slice(30, 42)}</Link></Col></Row>
                                <Row><Col><small>{item.txCount} transaction(s) in {item.buildTime} mili secs</small></Col></Row>
                            </Col>
                            <Col md={2} className="col-list">
                                <small>{parseInt(item.totalFee)} gwei</small>
                            </Col>

                        </Row>
                    </ListGroup.Item>
                ))}
                    <ListGroup.Item style={{backgroundColor:'rgba(0,0,0,0.0)'}}>
                        <Link to="/blocks">
                            <Button variant="secondary morebutton" size="sm" className="ml-2" style={{background:'#030f19',borderColor:'#484646'}}>More blocks</Button>
                        </Link>
                    </ListGroup.Item>
                </ListGroup>
            </Card.Body>
        </Card>
    );
};
export default LatestBlocks;