import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const AccountApi = {
    getCyxBalance: async function ({address}, cancel = false) {
            const response = await api.request({
                url: `Account/GetCYXBalance/contract?address=${address}`,
                method: "GET",
                signal: cancel ? cancelApiObject[this.getCyxBalance.name].handleRequestCancellation().signal : undefined,
            })
            return response.data
    },
    getBtccBalance: async function ({address}, cancel = false) {
        const response = await api.request({
            url: `Account/GetBtccBalance/contract?address=${address}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.getBtccBalance.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getEthcBalance: async function ({address}, cancel = false) {
        const response = await api.request({
            url: `Account/GetEthcBalance/contract?address=${address}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.getEthcBalance.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getUsdtBalance: async function ({address}, cancel = false) {
        const response = await api.request({
            url: `Account/GetUsdtBalance/contract?address=${address}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.getUsdtBalance.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getUsdcBalance: async function ({address}, cancel = false) {
        const response = await api.request({
            url: `Account/GetUsdcBalance/contract?address=${address}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.getUsdcBalance.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    GetByAllAssetsToken: async function ({address}, cancel = false) {
        const response = await api.request({
            url: `Account/GetByPublicAllAssetsToken/address?address=${address}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.GetByAllAssetsToken.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(AccountApi)