import Config from '../config.json'
import { AccountApi } from '../apis/AccountApi';
import { ContractApi } from '../apis/ContractApi';
import { BinanceApi } from '../apis/BinanceApi';
import { TransactionApi } from '../apis/TransactionApi';

import { getAddress, copyToClipboardButton } from '../class/Tools'
import { getProvider, isContract } from '../class/Evm'




// -=< React.Component >=- ------------------------------------------------------------------------------------------------- //
import { useState, useEffect } from 'react'
import { Card, Nav, Row, Col, Spinner } from 'react-bootstrap'
import { useParams } from "react-router-dom"

// -=< Components >=- ------------------------------------------------------------------------------------------------------ //
import AddressOverview from '../components/AddressOverview'
import AddressMoreInfo from '../components/AddressMoreInfo'
import AddressTxTable from '../components/AddressTxTable'
import AddressAssetsTable from '../components/AddressAssetsTable'
import ContractOverview from '../components/ContractOverview'
import ContractMoreInfo from '../components/ContractMoreInfo'


const axios = require('axios').default;

const initialState = {
    address: "",
    pageNumber: 1,
    pageSize: 25,
    isSecret: false
};


// ---------------------------------------------------------------------------------------------------------------------------- //
const Address = ({ networkName }) => {

    //console.log(params.walletAddress)

    // -=< Variables >=- ------------------------------------------------------------------------------------------------------ //
    const [balanceCheckAddress, setBalanceCheckAddress] = useState();
    const [txs, setTxs] = useState([])
    const [assetsList, setAssetsList] = useState([])
    const [otherBalance, setOtherBalance] = useState();

    const params = useParams()
    const [loading, setLoading] = useState(false)
    const [privateAddress, setPrivateAddress] = useState(false)
    const [error, setError] = useState(false)
    const [address, setAddress] = useState({
        address: params.address,
        balance: 0,
        value: 0.00,
        tokens: 0.00
    })
    const [contract, setContract] = useState(false)
    const [activeTab, setActiveTab] = useState('#assets')
    const [cardbody, setCardbody] = useState('')

    const [walletMessageLine1, setwalletMessageLine1] = useState('\u00A0')
    const [walletMessageLine2, setwalletMessageLine2] = useState('\u00A0')


    var list = [];

    // -=< Functions >=- ------------------------------------------------------------------------------------------------------ //
    const get_account_assets = async (addr, reload) => {
        if (reload)
            setLoading(true);
        setBalanceCheckAddress(addr);
        //console.log(balanceCheckAddress);
        await AccountApi.GetByAllAssetsToken({ 'address': addr })
            .then((response) => {
                if (response.code == -2)
                    setPrivateAddress(true);
                if (!privateAddress) {
                    list = [];
                    setAssetsList(list);
                    setLoading(true);
                    response.data.cryptoAccountBalances.forEach(element => {
                        list.push(
                            {
                                "symbol": element.contractName,
                                "name": element.contractName,
                                "amount": element.availableAmount,
                                "price": element.contractPrice,
                                "total": Number(element.availableBalance.toString().replace(',', '.')),
                                "fiatSymbol": "$"
                            });
                        list.sort((a, b) => a.symbol.localeCompare(b.symbol));
                        setAssetsList(list);
                    });
                    setLoading(false);
                }

            })
            .catch(function (error) {
                setError(true);
                setLoading(false);
                console.log('Adres Yok');
                console.log(error);
            });

    }

    const get_account_txnList = async (addr, reload) => {
        if (!privateAddress) {
            if (reload)
                setLoading(true);
            initialState.address = addr;
            await TransactionApi.getTransaction(initialState)
                .then((response) => {
                    var data = response?.data?.transactions.sort(function (a, b) { return parseInt(a.blockHeight) - parseInt(b.blockHeight) }).reverse();
                    setTxs(data);
                    setLoading(false);
                })
                .catch(function (error) {
                    setError(true);
                    console.log(error);
                });
    
        }
    }


    const loadTnx  = async() => {
        console.log('Loading Tnx....');
        if (!error && !privateAddress) {

            if (privateAddress)
                get_account_txnList(params.walletAddress, false)
    
            const c = (
                <AddressTxTable txs={txs} walletAddress={params.walletAddress} />
            )
            setCardbody(c)

        }
        
    }

    const loadAssets = async() => {
        console.log('Loading Asset....');
        if (!error && !privateAddress) {

            if (privateAddress)
                get_account_assets(params.walletAddress, false)
    
            const c = (
                <AddressAssetsTable assets={assetsList} walletAddress={params.walletAddress} />
            )
            setCardbody(c)

        }
        
    }

    // -=< Effects >=- ------------------------------------------------------------------------------------------------------ //
    useEffect(() => {

        if (params.walletAddress == '29yEJNpwmbrbHmo9i69XrcJuudMBgJrApF2sfVWi3gPX') {
            setwalletMessageLine1('This account is the first account created on the CYX Network. CYX was gifted a symbolic wallet for his contribution to Network Development.');
            setwalletMessageLine2('')
            
            setAddress({
                address: params.walletAddress,
                balance: 5000,  //balance,
                value: 4550
            });
        }

        if (!error && !privateAddress) {
            // Transactions Tab //
            if (activeTab === '#assets') {
                loadAssets();
            }
            else if (activeTab === '#tx') {
                loadTnx();
            }


            if (balanceCheckAddress != params.walletAddress) {
                setOtherBalance(0);
                get_account_assets(params.walletAddress, true);
                if (!privateAddress)
                    get_account_txnList(params.walletAddress, true);
                setActiveTab('#assets')
            }
        }

    }, [activeTab, params.walletAddress, assetsList, address, txs])

    //if params changes, reload page

    if (privateAddress) return (
        <main style={{ padding: "1rem 0" }} className='app-body'>
            <div style={{ color: 'white' }}>High Wealth Wallet! This Address belongs to a Private Wallet.</div>
        </main>
    )

    if (error) return (
        <main style={{ padding: "1rem 0" }} className='app-body'>
            <div style={{ color: 'white' }}>Address Not Found</div>
        </main>
    )

    if (loading) return (
        <main style={{ padding: "1rem 0" }} className='app-body'>

            <Spinner animation="border" style={{ display: 'inline-grid', marginTop: '25%', color: 'white' }} />
            <div style={{ color: 'white' }}>Loading Address</div>
        </main>
    )

    // -=< Render >=- ------------------------------------------------------------------------------------------------------ //
    return (
        <main style={{ padding: "1rem 0"}} className='app-body'>

            <h4 style={{color:'white'}} className="Title">Address :  {params.walletAddress}{copyToClipboardButton(params.walletAddress)}</h4>

            <Row className="justify-content-center">
                <Col xs={12} md={12} lg={6}>
                    {contract ? (
                        <ContractOverview address={address} />
                    ) : (
                        <AddressOverview address={address} tokenBalance={otherBalance} />
                    )}
                </Col>
                <Col xs={12} md={12} lg={6}>
                    {contract ? (
                        <ContractMoreInfo address={address} />
                    ) : (
                        <AddressMoreInfo address={address} messageLine1={walletMessageLine1} messageLine2={walletMessageLine2} />
                    )}
                </Col>
            </Row>
            <Row style={{ backgroundColor: 'rgba(0,0,0,0.0)'}}>
                <Col xs={12} md={12} lg={12}>
                    <Card className="infobox">
                        <Card.Header>
                            <Card.Title><b>Asset and Transactions List</b></Card.Title>
                            <Nav variant="tabs" defaultActiveKey="#assets" onSelect={(selectedKey) => setActiveTab(selectedKey)} >
                                <Nav.Item>
                                    <Nav.Link href="#assets">Assets</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#tx">Transactions</Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                                    <Nav.Link href="#inttx">Internal Tx</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#int">Contracts</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#disabled" disabled>
                                        Disabled
                                    </Nav.Link>
                                </Nav.Item> */}
                            </Nav>
                        </Card.Header>
                        <Card.Body>
                            {cardbody}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </main>
    );
}
export default Address