
import React from "react";
import { Table, Button, Card, Row, Col, ListGroup } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { getAddress, unixToDate, linkAddress } from '../class/Tools'
import { getProvider, isContract } from '../class/Evm'

const LatestBlocks = ({items}) => {
    console.log(items);
    return (
        <Card style={{marginBottom:'15px'}}> 
           <Card.Header>
                <Card.Title className="std-card-title">Block List</Card.Title>
            </Card.Header>
            <Card.Body>
            <Table striped bordered hover responsive size="sm" variant="light" >
                    <thead>
                        <tr>
                            <th>Block</th>
                            <th>Timestamp</th>
                            <th>Build Time</th>
                            <th>Tx Count</th>
                            <th>Nonce</th>
                            <th>Confirmation</th>
                            <th>Total Fee</th>
                            <th>Validatorn Address</th>
                            <th>Miner</th>
                        </tr>
                    </thead>
                    <tbody>
                    {items.map((item, idx) => (
                        <tr key={idx}>
                            <td><Link to={`/block/${item.height}`}>{item.height}</Link></td>
                            <td>{item.header.timeStamp} secs ago</td>
                            <td>{item.buildTime} {item.buildTime > 1 ? 'secs' : 'sec'}</td>
                            <td>{item.txCount}</td>
                            <td>{item.header.nonce}</td>
                            <td>{item.confirmation}</td>
                            <td>{parseInt(item.totalFee)} gwei</td>
                            <td>{item.validatorAddress}</td>
                            <td>{linkAddress(item.validatorAddress,1)}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};
export default LatestBlocks;