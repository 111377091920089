import React from "react";
import { Table, Button } from 'react-bootstrap'
import { Link } from "react-router-dom"

const AddressAssetsTable = ({assets, walletAddress}) => {
    //console.log(assets);
    // Render ---------------------------------------------------------------------------------------------------------- //
    return (
        <Table className="event-table" striped bordered hover responsive variant="light">
            <thead>
                <tr>
                    <th>Symbol</th>
                    <th>Name</th>
                    <th>Amount</th> 
                    <th>Price</th> 
                    <th>Total</th> 
                    {/* <th>Size</th>
                    <th>Price</th>
                    <th>Amount</th> */}
                </tr>
            </thead>
            <tbody>
            {assets.map((item, idx) => (
                <tr key={idx}>
               
                <td>{item.symbol}</td>
                <td>{item.name}</td>
                <td>{item.amount}</td>
                <td>{item.price}</td>
                <td>{item.total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
            </tr>
            ))}
            </tbody>
        </Table>
    );
};
export default AddressAssetsTable;