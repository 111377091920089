import { Link } from "react-router-dom";
import { Navbar, Nav, Button, Container, NavDropdown } from 'react-bootstrap'
import NavLastBlock from "./NavLastBlock";
//import MetamaskConnect from './MetamaskConnect'
//                    <MetamaskConnect web3Handler={web3Handler} account={account} networkName={networkName} />
//{ web3Handler, setNetwork, account, networkName, stats }

const Navigation = () => {
    return (



        <div>

            <Navbar expand="lg" variant="dark" className="NavBar">
                <Container>
                    <Navbar.Brand href="/" >
                        <img
                            src="/logo193.png"
                            width="240"
                            height="50"
                            className="d-inline-block align-top"
                        />
                        {/* &nbsp; <span className="navbar-brand ">CYX Scan</span> */}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/">Home</Nav.Link>
                            <Nav.Link as={Link} to="/txs">Transactions</Nav.Link>
                            <Nav.Link as={Link} to="/blocks">Blocks</Nav.Link>
                        </Nav>

                    </Navbar.Collapse>
                    {/* <NavLastBlock stats={stats} network={networkName}/> */}

                </Container>
            </Navbar>


            <div className="bg-image" style={{width:'100%', paddingBottom:'270px'}}></div>

        </div>


    )

}

export default Navigation;

//<Nav.Link as={Link} to="/apps">Applications</Nav.Link>
//<Nav.Link as={Link} to="/">Blockchain</Nav.Link>
//<Nav.Link as={Link} to="/">Analytics</Nav.Link>
//<Nav.Link as={Link} to="/">Resources</Nav.Link>