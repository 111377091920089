import axios from "axios"
import { notification } from "antd"

export const api = axios.create({
    baseURL: "https://cyxapi.azurewebsites.net/",
    //baseURL: "http://20.91.188.145:7159/",
})


export const binanceApi = axios.create({
    baseURL: "https://api.binance.com/api/v3/",
})

// defining a custom error handler for all APIs
const errorHandler = (error) => {
    const statusCode = error.response?.status

    if (error.code === "ERR_CANCELED") {
        notification.error({
            placement: "bottomRight",
            description: "API canceled!",
        })
        return Promise.resolve()
    }

    // logging only errors that are not 401
    if (statusCode && statusCode === 401) { 
        //console.log(statusCode);
        console.log('401 Error')
        sessionStorage.removeItem('AuthToken');
        sessionStorage.removeItem('AuthInformation');
        sessionStorage.removeItem('reduxState');
        //console.error(error)
    }

    if (statusCode && statusCode === 500) {
        //console.log("500 geldi");
        //console.error(JSON.stringify(error))
        //return Promise.resolve()
    }

    //return Promise.reject(createError('Network Error', null, null, error));

    return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})

binanceApi.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})